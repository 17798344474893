<script setup lang="ts">
import type { Notification, NotificationReadPayload } from '~/utils/types';

const op = ref();

const props = defineProps<{
  notification: Notification;
  onReadNotification: (
    payload: NotificationReadPayload,
    options?: { onSuccess?: () => void },
  ) => void;
  onDeleteNotification: (payload: NotificationReadPayload) => void;
}>();

const router = useRouter();

function redirectToNotificationUrl(notification: Notification) {
  if (!notification.url) return;

  props.onReadNotification(
    { notification_id: notification.id },
    {
      onSuccess: () => {
        if (notification.new_tab) {
          window.open(notification.url as string, '_blank');

          return;
        }

        router.push(notification.url as string);
      },
    },
  );
}

function readNotification(notification: Notification) {
  props.onReadNotification({
    notification_id: notification.id,
  }),
    op.value.toggle();
}

function deleteNotification(notification: Notification) {
  props.onDeleteNotification({
    notification_id: notification.id,
  });
}
</script>

<template>
  <article
    class="flex items-start gap-3 rounded-md px-2 py-3"
    :class="{
      'my-3 border border-solid border-blue-100 bg-blue-50':
        notification.is_unread,
    }"
  >
    <div
      class="flex aspect-square w-6 shrink-0 items-center justify-center rounded-full bg-yellow-500"
    >
      <i class="pi pi-star text-xs text-primary-inverse" />
    </div>

    <div
      class="flex-1 space-y-1 text-bluegray-500"
      :class="{
        'cursor-pointer hover:underline': notification.url,
      }"
      @click="redirectToNotificationUrl(notification)"
    >
      <div class="text-sm text-bluegray-900">
        {{ notification.title }}
      </div>

      <div v-if="notification.created_at" class="text-xs text-bluegray-500">
        {{ formatRelativeDate(notification.created_at) }}
      </div>
    </div>

    <PrimeButton
      text
      severity="secondary"
      @click="(event: MouseEvent) => op.toggle(event)"
    >
      <i class="pi pi-ellipsis-v text-base text-bluegray-800" />
    </PrimeButton>

    <PrimeOverlayPanel ref="op">
      <div class="-mx-4 -my-1 flex w-60 flex-col gap-1">
        <PrimeButton
          class="w-full !justify-start !font-normal !text-global-textColor"
          text
          severity="secondary"
          @click.stop="readNotification(notification)"
        >
          <i class="pi pi-check-square text-sm" />
          Oznacz jako przeczytane
        </PrimeButton>

        <PrimeButton
          class="w-full !justify-start !font-normal !text-global-textColor"
          text
          severity="secondary"
          @click.stop="deleteNotification(notification)"
        >
          <i class="pi pi-trash text-sm" />
          Usuń z listy
        </PrimeButton>
      </div>
    </PrimeOverlayPanel>
  </article>
</template>
