import { ref, watchEffect, provide, inject, computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useUserCourses } from '~/composables/useUserCourses';

export type SubMenuItem = {
  label: string;
  path: string;
  isActive: boolean;
  progress?: number;
};

export type MenuItemBasic = {
  label: string;
  icon: string;
  path: string;
  isActive: boolean;
  key: string;
  progress?: number;
  isInfoIcon?: boolean;
};

export type MenuItemAdvanced = {
  label: string;
  items: SubMenuItem[];
  icon: string;
  key: string;
  path: string;
};

export type ExpandedKeys = {
  [key: string]: boolean;
};

export function useContentNavigation() {
  const route = useRoute();

  const { data: courses } = useUserCourses();
  const { data: unpaidCourse } = useUnpaidCourse();

  const unpaidCourseArray = computed<MenuItemBasic[]>(() => {
    if (!unpaidCourse.value) {
      return [];
    }

    return [
      {
        label: unpaidCourse.value.title,
        icon: 'pi pi-book',
        path: `/checkout/${unpaidCourse.value.slug}`,
        isActive: route.path.includes(`/checkout/${unpaidCourse.value.slug}`),
        key: unpaidCourse.value.slug,
        isInfoIcon: true,
      },
    ];
  });

  const coursesMenu = computed(() => {
    if (!courses.value) return [];

    return courses.value.map((course) => {
      const lessons = course.lessons || [];

      const courseItems = lessons.map((lesson) => {
        let progress = 0;
        if (lesson.is_complete) {
          progress = 100;
        } else if (lesson.topics && lesson.topics.length) {
          const completedTopics = lesson.topics.filter(
            (topic) => topic.progress?.is_complete,
          );
          progress = (completedTopics.length / lesson.topics.length) * 100;
        }

        return {
          label: lesson.title,
          path: `/courses/${course.slug}/lessons/${lesson.slug}`,
          isActive: route.path.includes(
            `/courses/${course.slug}/lessons/${lesson.slug}`,
          ),
          progress,
        };
      });

      const courseSchema = {
        label: course.title,
        path: `/courses/${course.slug}`,
        icon: 'pi pi-book',
        key: course.id.toString(),
        items: courseItems,
      };

      return courseSchema;
    });
  });

  const { data: exams } = useUserExams();

  const examsMenu = computed(() => {
    if (!exams.value?.length) return [];

    return [
      {
        key: '2',
        label: 'Egzaminy',
        icon: 'pi pi-check-square',
        path: '/exams',
        items: exams.value.map((exam) => ({
          label: exam.title,
          path: `/exams/${exam.slug}`,
          isActive: route.path.includes(`/exams/${exam.slug}`),
        })),
      },
    ];
  });

  const items = ref<(MenuItemBasic | MenuItemAdvanced)[]>([
    {
      key: '0',
      label: 'Strona główna',
      icon: 'pi pi-home',
      path: '/',
      isActive: false,
    },
    ...unpaidCourseArray.value,
    ...coursesMenu.value,
    {
      key: '1',
      label: 'Materiały szkoleniowe',
      icon: 'pi pi-briefcase',
      path: '/materials',
      isActive: false,
    },
    ...examsMenu.value,
    {
      key: '5',
      label: 'Kontakt',
      icon: 'pi pi-question-circle',
      path: '/contact',
      isActive: false,
    },
  ]);

  watch([coursesMenu, unpaidCourseArray, examsMenu], () => {
    items.value = [
      {
        key: '0',
        label: 'Strona główna',
        icon: 'pi pi-home',
        path: '/',
        isActive: false,
      },
      ...unpaidCourseArray.value,
      ...coursesMenu.value,
      {
        key: '1',
        label: 'Materiały szkoleniowe',
        icon: 'pi pi-briefcase',
        path: '/materials',
        isActive: false,
      },
      ...examsMenu.value,
      {
        key: '5',
        label: 'Kontakt',
        icon: 'pi pi-question-circle',
        path: '/contact',
        isActive: false,
      },
    ];
  });

  const expandedKeys = ref({});

  const updateActiveState = (
    menuItems: (MenuItemBasic | MenuItemAdvanced)[],
  ) => {
    menuItems.forEach((item) => {
      if ('items' in item) {
        item.items.forEach((subItem) => {
          if (subItem.path.includes('exams')) {
            subItem.isActive = route.path === subItem.path;
          } else {
            subItem.isActive = route.path.includes(subItem.path);
          }
        });
      } else {
        item.isActive = route.path === item.path;
      }
    });
  };

  const updateExpandedKeys = (
    menuItems: (MenuItemBasic | MenuItemAdvanced)[],
  ) => {
    const newExpandedKeys: ExpandedKeys = {};

    menuItems.forEach((item) => {
      if ('items' in item) {
        const isAnySubItemActive = item.items.some(
          () => route.path.startsWith(item.path) && route.path !== '/',
        );
        if (isAnySubItemActive) {
          newExpandedKeys[item.key] = true;
        }
      }
    });

    expandedKeys.value = newExpandedKeys;
  };

  watchEffect(() => {
    updateActiveState(items.value);
    updateExpandedKeys(items.value);
  });

  return {
    items,
    expandedKeys,
  };
}

const navigationItemsSymbol = Symbol('navigationItems');
const expandedNavigationKeysSymbol = Symbol('expandedNavigationKeys');

export function useContentNavigationProvider() {
  const { items, expandedKeys } = useContentNavigation();

  provide(navigationItemsSymbol, items);
  provide(expandedNavigationKeysSymbol, expandedKeys);
}

export function useContentNavigationInjector() {
  const expandedNavigationKeys = inject<Ref<ExpandedKeys>>(
    expandedNavigationKeysSymbol,
  );
  const navigationItems = inject<Ref<(MenuItemAdvanced | MenuItemAdvanced)[]>>(
    navigationItemsSymbol,
  );

  if (!navigationItems || !expandedNavigationKeys) {
    throw new Error(
      'useContentNavigationInjector must be used after useContentNavigation',
    );
  }

  return {
    navigationItems,
    expandedNavigationKeys,
  };
}
