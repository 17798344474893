<script setup lang="ts">
const { data: user } = useUser();

useSidemenuProvider();
useContentNavigationProvider();

const { scY, toTop } = useScrollPosition();
const { data } = useRegulations();
</script>

<template>
  <div>
    <div class="flex min-h-screen bg-surface-section">
      <SideMenu class="hidden md:block" />
      <main class="min-h-screen flex-1 p-4 md:px-8 md:pt-6">
        <HeaderBar class="hidden md:flex" />
        <MobileHeaderBar class="md:hidden" />
        <LazyAcceptRegulations
          v-if="
            user?.has_accepted_regulations === 0 &&
            !useRoute().path.includes('regulations') &&
            data?.length
          "
        />

        <slot></slot>
      </main>
    </div>

    <PrimeButton
      v-if="scY > 300"
      v-tooltip="'Wróć na górę'"
      class="!fixed bottom-4 right-4 h-[42px] w-[42px]"
      v-auto-animate
      rounded
      @click="toTop"
    >
      <i class="pi pi-arrow-up text-lg text-white" />
    </PrimeButton>
  </div>
</template>
