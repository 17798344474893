import { useQueryClient, useMutation } from '@tanstack/vue-query';
import { authService } from '~/utils/api/authService';

export function useLogout() {
  const queryClient = useQueryClient();
  const { setToken } = useToken();
  const router = useRouter();

  const { mutate, isPending } = useMutation({
    mutationFn: () => authService.logout(),
    onSuccess: () => {
      setToken('');

      queryClient.removeQueries();

      router.push('/login');
    },
  });

  return {
    mutate,
    isPending,
  };
}
