<script setup lang="ts">
const { navigationItems, expandedNavigationKeys } =
  useContentNavigationInjector();
const { isSidemenuOpen } = useSidemenuInjector();

function openSideMenu(item: MenuItemBasic | MenuItemAdvanced) {
  if (!('items' in item)) return;

  isSidemenuOpen.value = true;
}
</script>

<template>
  <PrimePanelMenu
    :model="navigationItems"
    v-model:expandedKeys="expandedNavigationKeys"
  >
    <template #item="{ item }">
      <NuxtLink
        class="group relative flex cursor-pointer items-center rounded-md px-2.5 py-0.5 text-sm text-bluegray-900"
        :class="[
          {
            'bg-surface-ground': item.isActive,
          },
        ]"
        :to="!item.items ? item.path : ''"
        @click="openSideMenu(item)"
        v-tooltip="!isSidemenuOpen && item.label"
      >
        <span :class="[item.icon]" />

        <div
          class="absolute left-0 h-6 w-1 max-w-1 rounded-r-full bg-primary transition-all duration-100"
          :class="{
            'scale-y-100 opacity-100': item.isActive,
            'scale-y-0 opacity-50': !item.isActive,
          }"
        />

        <div
          class="flex items-center"
          :class="{
            'min-h-10': typeof item.progress !== 'number',
            'py-2': typeof item.progress === 'number',
          }"
        >
          <LazyProgressChart
            v-if="item.progress || item.progress === 0"
            class="h-5 min-h-5 w-5 min-w-5 text-primary"
            :progress="item.progress"
          />

          <span
            class="ml-3 text-sm"
            :class="{
              'font-medium': !!item.icon || item.isActive,
              'font-normal': !item.icon,
              'mr-3 w-[200px] overflow-hidden':
                typeof item.progress !== 'number',
              '!mr-0': item.isInfoIcon && isSidemenuOpen,
            }"
          >
            {{ item.label }}
          </span>
        </div>

        <div
          v-if="item.isInfoIcon && isSidemenuOpen"
          class="flex h-5 min-h-5 w-5 min-w-5 items-center justify-center rounded-full border border-orange-600 bg-orange-100/30"
        >
          <i class="pi pi-info-circle !text-xs text-orange-600"></i>
        </div>

        <i
          v-if="item.items?.length && isSidemenuOpen"
          class="pi pi-chevron-down ml-auto !text-xs"
        />
      </NuxtLink>
    </template>
  </PrimePanelMenu>
</template>
