<script setup lang="ts">
const { data: user } = useUser();
</script>

<template>
  <NuxtLink
    v-if="user"
    to="/membership"
    :class="{
      'pointer-events-none': user.zmidAssoc?.is_active,
    }"
  >
    <div
      class="flex items-center gap-2 rounded-md border p-2.5 text-sm font-semibold text-bluegray-800"
      :class="{
        'border-green-100 bg-green-50':
          user.zmidAssoc?.is_active && user.zmidAssoc?.end_date,
        'border-message-error-borderColor bg-message-error-background':
          !user.zmidAssoc?.is_active && !user.zmidAssoc?.end_date,
      }"
    >
      <div class="flex flex-col items-start gap-2 xl:flex-row xl:items-center">
        <MembershipBadge />
        Członkostwo ZMiD
      </div>

      <template v-if="!user.zmidAssoc?.is_active && !user.zmidAssoc?.end_date">
        <PrimeDivider
          class="!mx-1 !py-0 before:!border-bluegray-100"
          layout="vertical"
        />

        <div>Aktywuj</div>

        <i class="pi pi-arrow-right text-sm"></i>
      </template>
    </div>
  </NuxtLink>
</template>
