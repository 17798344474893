import { useQuery } from '@tanstack/vue-query';
import { courseService } from '~/utils/api/courseService';

export function useUnpaidCourse() {
  const { data: user } = useUser();
  const { data: courses } = useUserCourses();

  const courseSlug = computed(() => {
    if (!courses.value) return null;

    const signedCourseSlug = user.value?.signed_for_course;

    if (!signedCourseSlug) return null;

    const isUserHasCourse = courses.value.some((course) => {
      return course.slug === signedCourseSlug;
    });

    if (isUserHasCourse) return null;

    return signedCourseSlug;
  });

  return useQuery({
    queryKey: ['course', courseSlug],
    queryFn: () => {
      if (!courseSlug.value) {
        return Promise.resolve(null);
      }

      return courseService.getCourse({ slug: courseSlug.value });
    },
    enabled: !!courses,
  });
}
