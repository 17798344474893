<script setup lang="ts">
const { isSidemenuOpen } = useSidemenuInjector();
const { expandedNavigationKeys } = useContentNavigationInjector();

function toggleSidemenu() {
  if (
    isSidemenuOpen.value &&
    Object.keys(expandedNavigationKeys.value).length
  ) {
    expandedNavigationKeys.value = {};

    setTimeout(() => {
      isSidemenuOpen.value = false;
    }, 170);

    return;
  }
  isSidemenuOpen.value = !isSidemenuOpen.value;
}
</script>

<template>
  <aside
    class="group relative min-h-screen border border-surface-border bg-surface-card px-4 py-9 transition-all duration-300 ease-linear"
    :class="{
      'w-[300px] min-w-[300px]': isSidemenuOpen,
      'w-[69px] min-w-[69px]': !isSidemenuOpen,
    }"
  >
    <button
      class="absolute right-0 flex h-10 w-10 translate-x-1/2 -rotate-90 scale-75 items-center justify-center rounded-full border border-surface-border bg-surface-card p-2 opacity-0 transition-all duration-200 ease-out group-hover:rotate-0 group-hover:scale-100 group-hover:opacity-100"
      @click="toggleSidemenu"
    >
      <i
        class="pi pi-step-backward text-lg text-bluegray-600 transition-transform duration-300"
        :class="{
          'rotate-180': !isSidemenuOpen,
        }"
      />
    </button>

    <NuxtLink to="/">
      <AppLogo
        class="mb-8 ml-2.5 transition-opacity duration-700 ease-out"
        :class="{
          '[&__.letter]:opacity-0': !isSidemenuOpen,
        }"
      />
    </NuxtLink>

    <ContentNavigation class="sticky top-8 overflow-hidden" />
  </aside>
</template>
