import { fetcher } from '../fetcher';

const SEARCH_ENDPOINT = '/search';

export const searchService = {
  lessons: async (query: string) => {
    return await fetcher(`${SEARCH_ENDPOINT}?query=${query}`, {
      method: 'GET',
      schema: searchSchema,
    });
  },
  topics: async (query: string) => {
    return await fetcher(`${SEARCH_ENDPOINT}/topics?query=${query}`, {
      method: 'GET',
      schema: searchTopicsSchema,
    });
  },
};
