export function useScrollPosition() {
  const scY: Ref<number> = ref(0);
  let scTimer: number | null = null;

  const handleScroll = () => {
    if (scTimer !== null) return;
    scTimer = window.setTimeout(() => {
      scY.value = window.scrollY;
      clearTimeout(scTimer!);
      scTimer = null;
    }, 100);
  };

  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  onMounted(() => {
    window.addEventListener('scroll', handleScroll);
  });

  onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
  });

  return {
    scY,
    toTop,
  };
}
