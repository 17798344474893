<script setup lang="ts">
const op = ref();

const { data: user } = useUser();
const { isDarkTheme, updateTheme } = useTheme();
const { mutate: logout } = useLogout();
</script>

<template>
  <PrimeButton
    class="shrink-0"
    text
    severity="secondary"
    @click="(event: MouseEvent) => op.toggle(event)"
  >
    <UserAvatar />
    <i class="pi pi-chevron-down" />
  </PrimeButton>

  <PrimeOverlayPanel ref="op">
    <div
      class="flex w-56 flex-col gap-3 text-sm font-normal text-global-textColor"
    >
      <span class="font-semibold text-bluegray-900">{{ user?.name }}</span>
      <span>{{ user?.email }}</span>

      <PrimeDivider class="!my-0" />

      <div class="-mx-4 flex flex-col gap-3">
        <NuxtLink to="/my-account" @click="op.toggle()">
          <PrimeButton
            class="w-full !justify-start !font-normal !text-global-textColor"
            text
            severity="secondary"
          >
            <i class="pi pi-user" />
            Mój profil
          </PrimeButton>
        </NuxtLink>

        <NuxtLink to="/membership" @click="op.toggle()">
          <PrimeButton
            class="w-full !justify-start !font-normal !text-global-textColor"
            text
            severity="secondary"
          >
            <i class="pi pi-star" />
            Członkostwo
          </PrimeButton>
        </NuxtLink>

        <NuxtLink to="/settings" @click="op.toggle()">
          <PrimeButton
            class="w-full !justify-start !font-normal !text-global-textColor"
            text
            severity="secondary"
          >
            <i class="pi pi-cog" />

            Ustawienia
          </PrimeButton>
        </NuxtLink>

        <label class="flex items-center gap-2 px-4">
          <PrimeToggleSwitch
            :modelValue="isDarkTheme"
            @update:modelValue="updateTheme"
          />
          Tryb ciemny
        </label>

        <PrimeButton
          class="w-full !justify-start !font-normal !text-global-textColor"
          text
          severity="secondary"
          @click="logout"
        >
          <i class="pi pi-sign-out" />
          Wyloguj
        </PrimeButton>
      </div>
    </div>
  </PrimeOverlayPanel>
</template>
