import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query';
import { eventsService } from '~/utils/api/eventsService';

import type { Notification, NotificationReadPayload } from '~/utils/types';

export function useNotifications() {
  const queryClient = useQueryClient();

  const { data } = useQuery({
    queryKey: ['notifications'],
    queryFn: eventsService.getNotifications,
  });

  const unreadNotifications = computed<Notification[]>(() => {
    if (!data.value) return [];

    return data.value.filter((notification) => notification.is_unread === 1);
  });

  const { mutate: readNotification } = useMutation({
    mutationFn: (payload: NotificationReadPayload) =>
      eventsService.readNotification(payload),
    onMutate: (event) => {
      queryClient.setQueryData(['notifications'], (data: Notification[]) => {
        return data.map((notification) => {
          if (notification.id === event.notification_id) {
            return { ...notification, is_unread: 0 };
          }

          return notification;
        });
      });
    },
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ['notifications'] }),
  });

  const { mutate: readAllNotifications } = useMutation({
    mutationFn: (payload: NotificationReadPayload[]) => {
      const requests = payload.map((notification) =>
        eventsService.readNotification(notification),
      );
      return Promise.all(requests);
    },
    onMutate: (event) => {
      queryClient.setQueryData(['notifications'], (data: Notification[]) => {
        return data.map((notification) => {
          return { ...notification, is_unread: false };
        });
      });
    },
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ['notifications'] }),
  });

  const { mutate: deleteNotification } = useMutation({
    mutationFn: (payload: NotificationReadPayload) =>
      eventsService.deleteNotification(payload),
    onMutate: (event) => {
      queryClient.setQueryData(['notifications'], (data: Notification[]) => {
        return data.filter(
          (notification) => notification.id !== event.notification_id,
        );
      });
    },
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ['notifications'] }),
  });

  return {
    data,
    unreadNotifications,
    readNotification,
    readAllNotifications,
    deleteNotification,
  };
}
