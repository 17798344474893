<script setup lang="ts">
const op = ref();

const pt = {
  root: {
    class: 'relative [&__span]:absolute [&__span]:left-3 [&__span]:-top-1 ',
  },
};

const {
  data: notifications,
  unreadNotifications,
  readNotification,
  readAllNotifications,
  deleteNotification,
} = useNotifications();
</script>

<template>
  <PrimeButton
    class="shrink-0"
    text
    severity="secondary"
    @click="(event: MouseEvent) => op.toggle(event)"
  >
    <PrimeOverlayBadge :value="unreadNotifications.length" :pt="pt">
      <i class="pi pi-bell !text-2xl text-surface-900" />
    </PrimeOverlayBadge>
  </PrimeButton>

  <PrimeOverlayPanel
    class="max-h-[60vh] overflow-y-auto md:max-h-[80vh]"
    ref="op"
  >
    <div v-auto-animate class="w-full max-w-[456px] space-y-3">
      <div class="flex items-center justify-between gap-4">
        <h2 class="text-lg font-semibold text-bluegray-900">Powiadomienia</h2>
        <PrimeButton
          v-if="notifications?.length"
          rounded
          outlined
          text
          @click="
            readAllNotifications(
              unreadNotifications.map((notification) => ({
                notification_id: notification.id,
              })),
            )
          "
        >
          <i class="pi pi-check-square text-sm" />
          Oznacz wszystkie jako przeczytane
        </PrimeButton>
      </div>

      <ul v-auto-animate v-if="notifications?.length" class="divide-y-[1px]">
        <li
          :key="notification.id"
          v-for="notification in notifications.toReversed()"
        >
          <NotificationBox
            :notification="notification"
            :onReadNotification="readNotification"
            :onDeleteNotification="deleteNotification"
          />
        </li>
      </ul>

      <p v-if="!notifications?.length" class="text-bluegray-900">
        Brak powiadomień
      </p>
    </div>
  </PrimeOverlayPanel>
</template>
