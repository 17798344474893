<script setup lang="ts">
const { query, data: courses, isLoading } = useSearch();

const op = ref();

function handleSearchInput(event: InputEvent) {
  if (!query.value && op.value.visible) {
    op.value.toggle(event);

    return;
  }

  if (!op.value.visible) {
    op.value.toggle(event);
  }
}

watch(
  () => op.value?.visible,
  () => {
    if (op.value?.visible) return;

    query.value = '';
  },
);

type Topic = { title: string; slug: string };

type Lesson = {
  lesson_title: string;
  lesson_slug: string;
  topics: Topic[];
};

function getLessonsTopics(lessons: Lesson[]) {
  return lessons.reduce<(Topic & { lesson_slug: string })[]>((acc, lesson) => {
    const topics = lesson.topics.map((topic) => ({
      ...topic,
      lesson_slug: lesson.lesson_slug,
    }));
    return [...acc, ...topics];
  }, []);
}
</script>

<template>
  <div
    v-if="op?.visible"
    class="fixed left-0 top-0 z-10 h-screen w-screen bg-bluegray-900/50"
  ></div>

  <PrimeIconField
    class="z-10 mr-4 w-full text-xl text-surface-900 2xl:mr-auto 2xl:w-[523px]"
    :closeOnEscape="false"
  >
    <PrimeInputIcon class="pi pi-search ml-[10.5px]" />
    <PrimeInputText
      v-model="query"
      class="pl-8"
      size="small"
      placeholder="Wyszukaj"
      @input="handleSearchInput"
    />
  </PrimeIconField>

  <PrimeOverlayPanel
    class="mx-auto max-h-[80vh] w-5/6 overflow-auto shadow-lg md:w-auto md:shadow-none"
    ref="op"
  >
    <div class="lg:w-[491px]">
      <template :key="course.slug" v-for="course in courses?.slice(0, 2)">
        <PrimeDivider class="mb-3 mt-0" align="left" type="solid">
          <span class="bg-surface-card px-2 text-xs text-bluegray-600">
            {{ course.course_title }}
          </span>
        </PrimeDivider>

        <div class="-mx-4">
          <NuxtLink
            :key="topic.slug"
            v-for="topic in getLessonsTopics(course.lessons)?.slice(0, 8)"
            @click="(query = ''), op.toggle()"
            :to="`/courses/${course.course_slug}/lessons/${topic.lesson_slug}/topics/${topic.slug}`"
          >
            <PrimeButton
              class="!block w-full !text-left !font-normal !text-bluegray-900"
              text
              severity="secondary"
            >
              {{ topic.title }}
            </PrimeButton>
          </NuxtLink>
        </div>
      </template>

      <div v-auto-animate v-if="isLoading" class="text-right">
        <PrimeProgressSpinner class="ml-auto h-5 w-5" />
      </div>

      <div
        v-auto-animate
        v-else-if="courses?.length === 0"
        class="text-left text-sm text-bluegray-600"
      >
        Nie znaleziono żadnych wyników dla "{{ query }}"
      </div>
    </div>
  </PrimeOverlayPanel>
</template>
