<script setup lang="ts"></script>

<template>
  <header class="flex items-center">
    <ContentSearch class="w-full" />

    <MembershipInfo class="ml-4 hidden shrink-0 lg:flex" />

    <PrimeDivider class="hidden lg:flex" layout="vertical" />

    <div class="flex gap-1">
      <NotificationMenu />

      <UserMenu />
    </div>
  </header>
</template>
