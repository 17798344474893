<script setup lang="ts">
import { ref } from 'vue';

const { isDarkTheme, updateTheme } = useTheme();

const { items, expandedKeys } = useContentNavigation();

const { mutate: logout } = useLogout();

provide('items', items);
provide('expandedKeys', expandedKeys);

const visible = ref(false);
const route = useRoute();

watch(
  () => route.path,
  () => {
    visible.value = false;
  },
);
</script>

<template>
  <header class="flex items-center">
    <NuxtLink to="/">
      <AppLogo class="md:hidden" />
    </NuxtLink>

    <div class="ml-auto flex items-center">
      <NotificationMenu />

      <PrimeButton
        class="ml-auto md:hidden"
        text
        severity="secondary"
        @click="visible = true"
      >
        <i class="pi pi-bars text-lg" />
      </PrimeButton>
    </div>

    <PrimeDrawer v-model:visible="visible" class="w-full lg:max-w-[21rem]">
      <template #header>
        <ContentSearch />
      </template>

      <div class="mt-2 flex h-full flex-col gap-2">
        <p class="font-semibold">Menu</p>

        <ContentNavigation />

        <div class="flex flex-col gap-3">
          <label
            class="flex items-center gap-2 !pl-2.5 text-sm font-bold text-bluegray-900"
          >
            <PrimeToggleSwitch
              :modelValue="isDarkTheme"
              @update:modelValue="updateTheme"
            />
            Tryb ciemny
          </label>

          <NuxtLink to="/my-account">
            <PrimeButton
              class="w-full !justify-start !pl-2.5 !text-bluegray-900"
              text
              severity="secondary"
            >
              <i class="pi pi-user" />
              Mój profil
            </PrimeButton>
          </NuxtLink>

          <NuxtLink to="/membership">
            <PrimeButton
              class="w-full !justify-start !pl-2.5 !text-bluegray-900"
              text
              severity="secondary"
            >
              <i class="pi pi-star" />
              Członkostwo
            </PrimeButton>
          </NuxtLink>

          <NuxtLink to="/settings">
            <PrimeButton
              class="w-full !justify-start !pl-2.5 !text-bluegray-900"
              text
              severity="secondary"
            >
              <i class="pi pi-cog" />

              Ustawienia
            </PrimeButton>
          </NuxtLink>

          <PrimeButton
            class="w-full !justify-start !pl-2.5 !text-bluegray-900"
            text
            severity="secondary"
            @click="logout"
          >
            <i class="pi pi-sign-out" />
            Wyloguj
          </PrimeButton>
        </div>
      </div>
    </PrimeDrawer>
  </header>
</template>
