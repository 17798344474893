import { useQuery } from '@tanstack/vue-query';
import { searchService } from '~/utils/api/searchService';

export function useSearch() {
  const query = ref('');

  const { data, isLoading } = useQuery({
    queryKey: ['search', query],
    queryFn: () => {
      if (query.value.length === 0) {
        return Promise.resolve([]);
      }

      return searchService.topics(query.value);
    },
  });

  return { query, data, isLoading };
}
